import React, { useEffect, useState } from 'react';
import styles from './MyAds.module.scss';
import moment from 'moment';
import IconButtonText from 'components/template/IconButtonText/IconButtonText';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import StepOne from './DialogSteps/StepOne/StepOne';
import StepTwo from './DialogSteps/StepTwo/StepTwo';
import StepThree from './DialogSteps/StepThree/StepThree';
import StepFour from './DialogSteps/StepFour/StepFour';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { moneyStringToNumber, formatMoneyString } from 'shared/utility';
import {
  getMyAds,
  publishAd,
  updateAd,
  updateAdAsync,
  deleteAd,
  getMyDesiredAds,
  publishDesiredAd,
  updateDesiredAd,
  updateDesiredAdAsync,
  deleteDesiredAd,
} from 'store/ducks/transferAthletes';
import { getTeams } from 'store/ducks/teams';
import { getAgencyAthletes } from 'store/ducks/agencies';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Dialog,
  Input,
  Button,
  Menu,
  IconButton,
  Card,
  Spinner,
  SelectionButton,
  InfoCard,
} from 'components/template';
import { BsFillPlusCircleFill, BsCircle, BsCheckCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';
import { FaSearch, FaTrashAlt } from 'react-icons/fa';
import { TbUserDollar, TbUserSearch } from 'react-icons/tb';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { IoMdSend } from 'react-icons/io';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Checkbox } from 'components/template';
import { checkboxesAdvancedSearch } from '../../../shared/mocks';
import { Creators as layoutActions } from 'store/ducks/layout';
import ErrorMessages from 'shared/ErrorMessages';
import StepOneDesiredAthlete from './DialogStepsDesiredAthlete/StepOne/StepOne';
import StepTwoDesiredAthlete from './DialogStepsDesiredAthlete/StepTwo/StepTwo';
import StepThreeDesiredAthlete from './DialogStepsDesiredAthlete/StepThree/StepThree';
import StepFourDesiredAthlete from './DialogStepsDesiredAthlete/StepFour/StepFour';

export default function MyAds() {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  const initialModalState = {
    isOpenAnnounceType: false,
    isOpenMain: false,
    isOpenSuccess: false,
    isOpenError: false,
  };

  const initialData = {
    athlete: '',
    negotiation_type: 'SALE',
    is_active: true,
    sale_value: '',
    annual_salary: '',
    notes: ' ',
    teams: [],
    initial_date: moment().format('YYYY-MM-DD'),
    expiration_date: moment()
      .add(7, 'days')
      .format('YYYY-MM-DD'),
    isEditing: {
      edit: false,
      id: '',
    },
  };

  const initialDataDesiredAthlete = {
    amateur: null,
    female: user.data.has_only_female_permission === false ? null : true,
    nationalities: [],
    characteristics: '',
    comments: '',
    annual_salary: '0',
    negotiation_type: 'SALE',
    purchase_value: '0',
    team_confidential: null,
    teams: [],
    initial_date: moment().format('YYYY-MM-DD'),
    expiration_date: moment()
      .add(7, 'days')
      .format('YYYY-MM-DD'),
    isEditing: {
      edit: false,
      id: '',
    },
  };

  const [adsData, setAdsData] = useState(initialData);
  const [desiredAthleteData, setDesiredAtlheteData] = useState(initialDataDesiredAthlete);
  const [modalState, setModalState] = useState(initialModalState);
  const [step, setStep] = useState(1);
  const initialStepThreeConfig = {
    checkedShowAll: true,
    allTeamsChecked: false,
    filters: {
      ids: '',
      q: '',
      country: '',
      division: '',
      limit: 50,
    },
  };
  const [stepThreeConfig, setStepThreeConfig] = useState(initialStepThreeConfig);
  const [errorMessage, setErrorMessage] = useState('');
  const [allChecked, setAllChecked] = useState(false);
  const [desiredTab, setDesiredTab] = useState(false);
  const [selectedArr, setSelectedArr] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    negotiation_type: '',
    status: '',
  });
  const [announceType, setAnnounceType] = useState(0);

  const myAds = useSelector(state => state.transferAthletes);
  const dispatch = useDispatch();
  const userIsAgency = user.data?.organization?.id?.type === 'agency';
  const teams = useSelector(state => state.teams.teams);
  const agencyAthletes = useSelector(state => state.agencies.athletes);
  const teamId = user.data?.organization?.teamId;

  console.log(desiredAthleteData);

  const allCheckedArr = [];

  function openAnnounceByType(type) {
    setModalState({
      ...modalState,
      isOpenAnnounceType: false,
      isOpenMain: true,
    });
    setAdsData(initialData);
    setDesiredAtlheteData(initialDataDesiredAthlete);
    setAnnounceType(type);
  }

  function onCloseAnnounceType() {
    setModalState({
      ...modalState,
      isOpenAnnounceType: false,
    });
  }

  function onClose() {
    setModalState({
      ...modalState,
      isOpenMain: false,
    });
    setAdsData(initialData);
    setStepThreeConfig(initialStepThreeConfig);
    setStep(1);
  }

  function onCloseSuccess() {
    setTimeout(() => {
      setModalState({
        ...modalState,
        isOpenMain: false,
        isOpenSuccess: false,
      });
    }, 1000);
  }

  const loadAds = () => {
    if (desiredTab) {
      dispatch(getMyDesiredAds());
    } else {
      dispatch(getMyAds());
    }
  };

  useEffect(() => {
    loadAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desiredTab]);

  useEffect(() => {
    if (userIsAgency) {
      dispatch(getAgencyAthletes(user.data?.organization?.id?.id, { has_contract: false }));
    } else {
      dispatch(getTeams(null, teamId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, step]);

  useEffect(() => {
    if (modalState.isOpenSuccess) {
      onCloseSuccess();
      setTimeout(() => {
        loadAds();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.isOpenSuccess]);

  useEffect(() => {
    if (allChecked) {
      setSelectedArr(allCheckedArr);
    } else {
      setSelectedArr([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChecked]);

  const filteredData = myAds.data?.filter(item => {
    return (
      (desiredTab || item.athlete?.fullname?.toLowerCase().includes(filters.name)) &&
      (filters.negotiation_type === '' || item.negotiation_type === filters.negotiation_type) &&
      (filters.status === '' || item.is_active === (filters.status === 'active' ? true : false))
    );
  });

  function getNavbarText(step) {
    switch (step) {
      case 1:
        if (announceType === 2) return t('transferMarket.adData');
        return t('proj.text14');
      case 2:
        if (announceType === 2) return t('organi.text23');
        return t('transferMarket.adData');
      case 3:
        if (announceType === 2) return t('transferMarket.defineAudience');
        return t('transferMarket.defineAudience');
      case 4:
        if (announceType === 2) return t('transferMarket.confirmAd');
        return t('transferMarket.confirmAd');
      default:
        return '';
    }
  }

  let content;

  if (myAds.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (myAds.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        {t('proj.text11')}
      </Card>
    );
  } else if (myAds.data) {
    const tableData = filteredData?.map(item => {
      allCheckedArr.push(item.id);
      const checkbox = (
        <Checkbox
          checked={selectedArr.includes(item.id)}
          onChange={e => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedArr([...selectedArr, item.id]);
            } else {
              setSelectedArr(selectedArr.filter(id => id !== item.id));
            }
          }}
        />
      );
      const annoucedAthlete = desiredTab ? (
        <InfoCard className={styles.infoCard} data={item} isDesired={true} />
      ) : (
        <AthleteRow
          name={item.athlete?.fullname ?? ''}
          nickname={item.athlete?.nickname ?? ''}
          img={item.athlete?.photo}
          id={item.athlete?.id}
        />
      );
      const negotiationType = t(
        `${desiredTab ? 'desiredNegotiationOptions' : 'negotiationOptions'}.${item.negotiation_type}`
      );
      const saleValue = `${formatMoneyString(desiredTab ? item.purchase_value : item.sale_value)} €`;
      const createdAt = moment(item.initial_date ?? '').format('DD/MM/YYYY');
      var dataAtual = moment();
      var dataVencimento = moment(item.expiration_date);
      const isExpired = dataAtual.isAfter(dataVencimento, 'day');
      const expirationDate = (
        <div>
          {isExpired ? (
            <p className={styles.isExpiredDate}>{moment(item.expiration_date ?? '').format('DD/MM/YYYY')}</p>
          ) : (
            <p>{moment(item.expiration_date ?? '').format('DD/MM/YYYY')}</p>
          )}
        </div>
      );

      const interested = desiredTab ? item.in_negotiation_count : item.interested_count;
      const views = desiredTab ? item.offered_count : item.views_count;
      const progress = interested === 0 && views === 0 ? 0 : (interested / views) * 100;
      const interestedVisualized = (
        <div className={styles.interestedVisualizedContainer}>
          <div className={styles.interestedVisualizedCounter}>
            <span style={{ color: '#5A00FF' }}>{interested}</span>
            <span style={{}}>/{views}</span>
          </div>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `${Math.min(progress, 100)}%` }}></div>
          </div>
        </div>
      );
      const switchButton = (
        <div className={styles.stepThreeSwitchContainer}>
          <Switch
            checked={item.is_active && !isExpired}
            onChange={async checked => {
              try {
                if (desiredTab) {
                  await updateDesiredAdAsync(item.id, { is_active: checked });
                } else {
                  await updateAdAsync(item.id, { is_active: checked });
                }
                loadAds();
              } catch (e) {
                const status = e?.response?.status;
                if (status === 402) {
                  dispatch(layoutActions.showExcededPlanLimit());
                }
              }
            }}
            className={styles.stepThreeSwitch}
            disabled={isExpired}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#5A00FF"
            offColor="#ffffff"
            offHandleColor="#5A00FF"
            onHandleColor="#ffffff"
            handleDiameter={16}
            height={20}
            width={42}
          />
        </div>
      );
      const status = !isExpired && item.is_active ? t('transferMarket.active') : t('transferMarket.inactive');
      const editAd = (
        <div
          className={styles.editAd}
          onClick={() => {
            setStep(1);
            setModalState({ ...modalState, isOpenMain: true });
            const newStepThreeConfig = { ...initialStepThreeConfig };
            if (item.teams && item.teams.length > 0) {
              newStepThreeConfig.checkedShowAll = false;
            }
            setStepThreeConfig(newStepThreeConfig);
            if (desiredTab) {
              setDesiredAtlheteData({
                nationalities: item.nationalities,
                amateur: item.amateur,
                female: item.female,
                position: item.position,
                foot: item.foot,
                min_age: item.min_age,
                max_age: item.max_age,
                has_contract: item.has_contract,
                contract_end: item.contract_end,
                characteristics: item.characteristics,
                negotiation_type: item.negotiation_type,
                purchase_value: item.purchase_value,
                annual_salary: item.annual_salary,
                monthly_salary: item.monthly_salary,
                comments: item.comments,
                teams: item.teams,
                initial_date: item.initial_date,
                expiration_date: item.expiration_date,
                isEditing: { edit: true, id: item.id },
                team_confidential: item.team_confidential,
                is_visible_to_agencies: item.is_visible_to_agencies,
              });
            } else {
              setAdsData({
                athlete: item.athlete.id,
                negotiation_type: item.negotiation_type,
                is_active: item.is_active,
                sale_value: item.sale_value,
                annual_salary: item.annual_salary,
                notes: item.notes,
                teams: item.teams,
                initial_date: item.initial_date,
                expiration_date: item.expiration_date,
                isEditing: { edit: true, id: item.id },
              });
            }
          }}
        >
          {t('transferMarket.editAd')}
        </div>
      );

      return [
        checkbox,
        annoucedAthlete,
        negotiationType,
        saleValue,
        createdAt,
        expirationDate,
        interestedVisualized,
        switchButton,
        status,
        editAd,
      ];
    });

    content = (
      <Table
        flat
        theads={[
          <Checkbox checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
          t(desiredTab ? 'organi.desiredAthlete' : 'organi.text22'),
          t('organi.text23'),
          t('organi.text24'),
          t('organi.text25'),
          t('organi.text26'),
          t(desiredTab ? 'organi.inNegotiationsOferreds' : 'organi.text27'),
          t('organi.text28'),
          '',
          '',
        ]}
        className={styles.listTable}
        data={tableData}
      />
    );
  }

  const isLoadingAthletes = userIsAgency ? agencyAthletes?.loading : teams?.loading;
  const athletesData = userIsAgency ? agencyAthletes?.data : teams?.data;

  const announceTypeSteps =
    announceType === 1 ? (
      <>
        {step === 1 && (
          <StepOne adsData={adsData} setAdsData={setAdsData} loading={isLoadingAthletes} athletes={athletesData} />
        )}
        {step === 2 && <StepTwo adsData={adsData} setAdsData={setAdsData} athletes={athletesData} />}
        {step === 3 && (
          <StepThree
            adsData={adsData}
            setAdsData={setAdsData}
            stepThreeConfig={stepThreeConfig}
            setStepThreeConfig={setStepThreeConfig}
          />
        )}
        {step === 4 && <StepFour adsData={adsData} setAdsData={setAdsData} athletes={athletesData} />}
      </>
    ) : (
      <>
        {step === 1 && <StepOneDesiredAthlete adsData={desiredAthleteData} setAdsData={setDesiredAtlheteData} />}
        {step === 2 && <StepTwoDesiredAthlete adsData={desiredAthleteData} setAdsData={setDesiredAtlheteData} />}
        {step === 3 && (
          <StepThreeDesiredAthlete
            adsData={desiredAthleteData}
            setAdsData={setDesiredAtlheteData}
            stepThreeConfig={stepThreeConfig}
            setStepThreeConfig={setStepThreeConfig}
          />
        )}
        {step === 4 && <StepFourDesiredAthlete adsData={desiredAthleteData} setAdsData={setDesiredAtlheteData} />}
      </>
    );

  const showToast = msgTranslate => {
    dispatch(
      layoutActions.showToast({
        content: t(msgTranslate),
        duration: 3000,
      })
    );
  };

  const hideToast = () => {
    dispatch(layoutActions.hideToast());
  };

  const validateStep = step => {
    if (announceType === 1) {
      //Anunciar atleta do meu elenco, step 1
      if (step === 1) {
        if (adsData.athlete && adsData.athlete !== 0 && adsData.athlete !== '') {
          hideToast();
        } else {
          showToast('transferMarket.step1error');
          return false;
        }
      }
      //Anunciar atleta do meu elenco, step 2
      if (step === 2) {
        if (
          adsData.sale_value == null ||
          adsData.sale_value === '' ||
          adsData.sale_value === 0 ||
          adsData.annual_salary == null ||
          adsData.annual_salary === '' ||
          adsData.annual_salary === 0 ||
          adsData.notes.trim().length === 0
        ) {
          showToast('transferMarket.step2error');
          return false;
        } else {
          hideToast();
        }
      }
    } else {
      //Anunciar perfil de atleta desejado, step 1
      if (step === 1) {
        let ageIsOk = false;
        if (desiredAthleteData.min_age && desiredAthleteData.max_age) {
          ageIsOk = parseInt(desiredAthleteData.max_age) >= parseInt(desiredAthleteData.min_age);
          if (!ageIsOk) {
            showToast('erros.ageMinMax');
            return false;
          }
        }
        if (ageIsOk && desiredAthleteData.position) {
          hideToast();
        } else {
          showToast('erros.generalForm');
          return false;
        }
      }
      //Anunciar perfil de atleta desejado, step 3
      if (step === 3) {
        if (desiredAthleteData.team_confidential == null) {
          showToast('erros.generalForm');
          return false;
        } else {
          hideToast();
        }
      }
    }
    return true;
  };

  const publishClick = async () => {
    if (announceType === 1) {
      //Anunciar atleta do meu elenco
      const newAdsData = {
        ...adsData,
        sale_value: moneyStringToNumber(adsData.sale_value),
        annual_salary: moneyStringToNumber(adsData.annual_salary),
      };

      if (newAdsData.isEditing.edit) {
        dispatch(updateAd(newAdsData.isEditing.id, newAdsData));
        setModalState({
          ...modalState,
          isOpenMain: false,
          isOpenSuccess: true,
        });
      } else {
        publishAd(newAdsData).then(res => {
          const responseError = res.response?.data?.error;
          const athleteError = res.response?.data?.athlete?.[0];
          const detailError = res.response?.data?.detail;
          const deviceError = res.response?.data?.error === 'user logged in another device';
          const hasData = res.data;

          if (responseError === 'An active listing for this athlete already exists.') {
            setErrorMessage(t('transferMarket.modalError1'));
          } else if (athleteError === 'Este campo não pode ser nulo.') {
            setErrorMessage(t('transferMarket.modalError2'));
          } else if (detailError === ErrorMessages.excededPlanLimit) {
            dispatch(layoutActions.showExcededPlanLimit());
            return;
          } else if (deviceError) {
            setErrorMessage(t('transferMarket.modalError4'));
          }

          if (hasData) {
            setModalState({
              isOpenError: false,
              isOpenMain: false,
              isOpenSuccess: true,
            });
          } else {
            setModalState({
              ...modalState,
              isOpenError: true,
            });
          }
        });
      }
    } else {
      //Anunciar perfil de atleta desejado
      const newAdsData = {
        ...desiredAthleteData,
        max_age: desiredAthleteData.max_age ? parseInt(desiredAthleteData.max_age) : null,
        min_age: desiredAthleteData.min_age ? parseInt(desiredAthleteData.min_age) : null,
        monthly_salary: desiredAthleteData.monthly_salary
          ? moneyStringToNumber(desiredAthleteData.monthly_salary)
          : null,
        annual_salary: desiredAthleteData.annual_salary ? moneyStringToNumber(desiredAthleteData.annual_salary) : null,
        purchase_value: desiredAthleteData.purchase_value
          ? moneyStringToNumber(desiredAthleteData.purchase_value)
          : null,
        nationalities: desiredAthleteData.nationalities?.map(n => n.code).join(',') ?? '',
        team_confidential: desiredAthleteData.team_confidential,
        is_visible_to_agencies: desiredAthleteData.is_visible_to_agencies,
      };

      if (newAdsData.isEditing.edit) {
        dispatch(updateDesiredAd(newAdsData.isEditing.id, newAdsData));
        setModalState({
          ...modalState,
          isOpenMain: false,
          isOpenSuccess: true,
        });
      } else {
        publishDesiredAd(newAdsData).then(res => {
          const detailError = res.response?.data?.detail;
          if (detailError === ErrorMessages.excededPlanLimit) {
            dispatch(layoutActions.showExcededPlanLimit());
            return;
          }
          const hasData = res.data;
          if (hasData) {
            setModalState({
              isOpenError: false,
              isOpenMain: false,
              isOpenSuccess: true,
            });
          } else {
            setModalState({
              ...modalState,
              isOpenError: true,
            });
          }
        });
      }
    }
  };

  const desiredLimit = user.data.plan.transfer_market_intention_ads_limit ?? 0;
  return (
    <div className={styles.cont}>
      <div className={styles.titulo}>
        <h1>{t('user.text32')}</h1>
        <p>{t('user.text32sub')}</p>
      </div>
      <div className={styles.filters}>
        <Button
          outline
          rounded
          color="green"
          style={{
            marginRight: '15px',
            fontSize: '14px',
            fontWeight: 700,
          }}
          onClick={async () => {
            if (!allChecked && selectedArr.length < 1) {
              return;
            }

            if (desiredTab) {
              await Promise.all(selectedArr.map(id => deleteDesiredAd(id)));
            } else {
              await Promise.all(selectedArr.map(id => deleteAd(id)));
            }
            loadAds();
          }}
          disabled={!allChecked && selectedArr.length < 1}
        >
          <FaTrashAlt style={{ marginRight: '10px', marginBottom: '-2px' }} />
          {t('buttons.excluir')}
        </Button>
        {!userIsAgency && desiredLimit !== 0 && (
          <SelectionButton
            items={[
              { label: t('club.box28'), value: false },
              { label: t('club.box30'), value: true },
            ]}
            selected={desiredTab}
            onClick={val => setDesiredTab(val)}
          />
        )}
        <div className={styles.inputText}>
          <p>{t('proj.filtrarPor')}</p>
        </div>
        {!desiredTab && (
          <div className={styles.input}>
            <Input
              rounded
              name="name"
              onChange={e =>
                setFilters({
                  ...filters,
                  name: e.target.value.toLowerCase(),
                })
              }
              placeholder={t('proj.text24')}
              icon={<FaSearch className={styles.searchIcon} />}
              iconPosition="right"
            />
          </div>
        )}
        <Menu
          title={t('athlete_card.text8')}
          rounded
          allowEmpty
          placeholder={t('athlete_card.text8')}
          className={styles.menu}
          options={checkboxesAdvancedSearch.negotiation_type.map(item => ({
            label: t(`negotiationOptions.${item.value}`),
            value: item.value,
          }))}
          value={filters['negotiation_type']}
          onClear={() => setFilters({ ...filters, negotiation_type: '' })}
          setValue={value => setFilters({ ...filters, negotiation_type: value })}
        />
        <Menu
          title={t('athlete_card.text9')}
          rounded
          allowEmpty
          placeholder={t('athlete_card.text9')}
          className={styles.menu}
          options={checkboxesAdvancedSearch.ad_type.map(item => ({
            label: t(`transferMarket.${item.value}`),
            value: item.value,
          }))}
          value={filters['status']}
          onClear={() => setFilters({ ...filters, status: '' })}
          setValue={value => setFilters({ ...filters, status: value })}
        />
        <div className={styles.lastButton}>
          <IconButtonText
            onClick={() => {
              if (userIsAgency || desiredLimit === 0) {
                openAnnounceByType(1);
              } else {
                setModalState({
                  ...modalState,
                  isOpenAnnounceType: true,
                });
              }
            }}
            color="lightPurple1"
            text={t('user.text33')}
            icon={<BsFillPlusCircleFill />}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>{content}</div>
      <Dialog
        isOpen={modalState.isOpenAnnounceType}
        onClose={onCloseAnnounceType}
        className={{
          container: styles.dialog_sm,
          header: styles.dialogHeader,
          body: styles.dialogBody,
        }}
        mainTitle={t('app.text9')}
      >
        <div className={styles.announceTypeDialog}>
          <h2 className="text-center">{t('transferMarket.announceType')}</h2>
          <div>
            <button onClick={() => openAnnounceByType(1)}>
              <TbUserDollar />
              <span>{t('transferMarket.mySquadAthlete')}</span>
            </button>
            <button onClick={() => openAnnounceByType(2)}>
              <TbUserSearch />
              <span>{t('transferMarket.AthleteProfile')}</span>
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={modalState.isOpenMain}
        onClose={onClose}
        className={{
          container: styles.dialog,
          header: styles.dialogHeader,
          body: styles.dialogBody,
        }}
        mainTitle={t('app.text9')}
      >
        <div className={styles.navbar_container}>
          {[1, 2, 3, 4].map(num => (
            <div
              key={num}
              className={
                step === num ? styles.selected : step > num ? styles.dialogNavbar : step < num ? styles.will_select : ''
              }
              onClick={() => {
                let validated = true;
                for (let i = step; i < num; i++) {
                  if (validated) {
                    setStep(i);
                  }
                  validated = validated && validateStep(i);
                }
                if (validated) {
                  setStep(num);
                }
              }}
            >
              {step >= num ? <BsCheckCircleFill /> : <BsCircle />}
              <span>{`${num}. ${getNavbarText(num)}`}</span>
            </div>
          ))}
        </div>
        {announceTypeSteps}
        <div className={styles.dialogFooter}>
          <div className={styles.footerButtons}>
            {step > 1 && (
              <IconButton
                className={styles.dialogBack}
                text={t('home.box17')}
                icon={<FiChevronLeft />}
                onClick={() => {
                  setStep(step - 1);
                }}
              />
            )}
            {step === 4 ? (
              <IconButton
                className={styles.dialogContinue}
                text={t('transferMarket.publish')}
                icon={<IoMdSend />}
                onClick={publishClick}
              />
            ) : (
              <IconButton
                className={styles.dialogContinue}
                text={t('rest.box25')}
                icon={<FiChevronRight />}
                onClick={() => {
                  if (validateStep(step)) {
                    setStep(step + 1);
                  }
                }}
              />
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        className={{
          container: styles.successModal,
        }}
        isOpen={modalState.isOpenSuccess}
        showBackdrop={false}
      >
        <BsFillCheckCircleFill />
        <span>{t('transferMarket.publishSuccess')}</span>
      </Dialog>
      <Dialog
        className={{
          container: styles.errorModal,
        }}
        isOpen={modalState.isOpenError}
        showBackdrop={false}
      >
        <AiFillCloseCircle />
        <span>{errorMessage}</span>
      </Dialog>
    </div>
  );
}
