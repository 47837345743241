import React from 'react';
import styles from './TopBar.module.scss';
import { useSelector } from 'react-redux';
import ClubsLogo from 'assets/ico/menu/clubs.svg';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { useTranslation } from 'react-i18next';
import NationalClubs from '../NationalClubs';
import ForeignClubs from 'pages/ForeignClubs/ForeignClubs';
import FootlinkClubs from 'pages/FootlinkClubs/FootlinkClubs';

import { withRouter } from 'react-router-dom';

function TopBar({ location }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);
  const items = [
    user.permission_gender !== 'female'
      ? {
          label: t('home.text4'),
          link: `/clubes-brasileiros/serie-a`,
          isActive: location.pathname.includes(`/clubes-brasileiros/serie-a`),
          component: <NationalClubs />,
        }
      : {
          label: t('home.text4'),
          link: `/clubes-brasileiros/todos`,
          isActive: location.pathname.includes(`/clubes-brasileiros/todos`),
          component: <NationalClubs />,
        },
    {
      label: t('organi.text15'),
      link: `/clubes-estrangeiros`,
      isActive: location.pathname.includes(`/clubes-estrangeiros`),
      component: <ForeignClubs />,
    },
    {
      label: t('home.footlinkClubs'),
      link: `/clubes-footlink`,
      component: <FootlinkClubs />,
    },
  ];

  const page = {
    title: t('compe.text12'),
    subTitle: t('organi.text16'),
  };

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <span className={styles.icon}>
          <img draggable="false" src={ClubsLogo} alt={t('nav_drawer.clubes')} />
        </span>
        <div className={styles.clubInfo}>
          <h1>{page.title}</h1>
          <span className={styles.subtitle}>{page.subTitle}</span>
        </div>
      </div>
      <NavigationBar items={items} />
    </div>
  );
}

export default withRouter(TopBar);
