import styles from './GenderProfileButton.module.scss';
import { BiChevronDown } from 'react-icons/bi';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/template';
import GenderCard from './GenderCard';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { PiGenderFemaleBold, PiGenderMaleBold } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

export default function GenderProfileButton({ className }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const layout = useSelector(state => state.layout);
  const mascFlag = <PiGenderMaleBold color="#fff" />;
  const femiFlag = <PiGenderFemaleBold color="#fff" />;
  const generalFlag = (
    <div className={styles.iconGeneralGender}>
      <PiGenderMaleBold color="#360099" />
      <PiGenderFemaleBold color="#c300ff" />
    </div>
  );
  const [isGenderCardVisible, setGenderCardVisibility] = useState(false);
  const user = useSelector(state => state.user.data);

  const genderDescription = {
    male: t('radioAdvancedSearch.male'),
    female: t('radioAdvancedSearch.female'),
    general: t('radioAdvancedSearch.male_female'),
  };

  const genderImg = {
    male: mascFlag,
    female: femiFlag,
    general: generalFlag,
  };

  const navDrawerToggler = () => {
    dispatch(layoutCreators.hideNavDrawer());
  };

  return (
    <div
      className={[
        styles.languageButton,
        className,
        ...(user.permission_gender === 'female' ? [styles.femaleBackgroundColor] : []),
        ...(user.permission_gender === 'male' ? [styles.maleBackgroundColor] : []),
        ...(user.permission_gender === 'general' ? [styles.generalBackgroundColor] : []),
      ].join(' ')}
    >
      <Button
        className={!user.has_general_gender_permission ? styles.noHover : ''}
        onClick={() => {
          if (user.has_general_gender_permission) {
            setGenderCardVisibility(!isGenderCardVisible);
            navDrawerToggler(false);
          }
        }}
      >
        <div className={styles.genderLabel}>
          {genderImg[user.permission_gender]}
          {genderDescription[user.permission_gender]}
        </div>
        <GenderCard
          isVisible={isGenderCardVisible && layout.isNavDrawerVisible === false}
          hide={() => {
            setGenderCardVisibility(false);
          }}
        />
      </Button>
      {user.has_general_gender_permission && (
        <BiChevronDown className={user.permission_gender === 'general' ? styles.arrowDownGeneral : styles.arrowDown} />
      )}
    </div>
  );
}
